import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

// color: #FFF;

// /* AmandaAI/Desktop/Button */
// font - family: "Suisse Int'l";
// font - size: 0.875rem;
// font - style: normal;
// font - weight: 600;
// line - height: 1.125rem; /* 128.571% */
// letter - spacing: 0.0875rem;

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 text-sm font-semibold leading-haus tracking-haus",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground hover:bg-primary/90 rounded-[50px] border-none hover:border-hausorange border border-opacity-40 hover:text-white hover:bg-hausorange transition-all delay-75 duration-300",
        success:
          "bg-hausspringgreen border-hausspringgreen text-primary-foreground hover:bg-hausspringgreen/70 rounded-[50px] border border-opacity-40",
        outline:
          "border bg-background hover:border-accent hover:text-accent-foreground disabled:hover:text-black disabled:hover:text-opacity-70 disabled:hover:border-black disabled:hover:border-opacity-40 rounded-[50px] border-black border border-opacity-40",
        destructive:
          "bg-destructive text-white hover:bg-destructive/80 rounded-[50px]",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80 rounded-[50px] hover:text-white hover:bg-hausorange transition-all delay-75 duration-300",
        ghost: "rounded-full hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-10 px-7 py-2",
        sm: "h-9 px-3",
        lg: "h-11 px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const HausButton = React.forwardRef<HTMLButtonElement, ButtonProps & { noUppercase?: boolean }>(
  ({ className, noUppercase = false, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        style={{
          letterSpacing: "0.0875rem",
          opacity: props.disabled ? 0.5 : 1,
        }}
        className={cn(
          buttonVariants({
            variant,
            size,
            className,
          }),
          !noUppercase && "uppercase",
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
HausButton.displayName = "HausButton";

export { buttonVariants, HausButton };

